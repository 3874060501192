import { Injectable, input, model } from '@angular/core';
import { Model } from '../../models/model/model.model';
import { SchemaKeys, SchemaLevel } from '../../models/schema/schema.model';
import { ModelField } from '../../models/model/field.model';
import { firstValueFrom } from 'rxjs';
import { ModelCrudService } from '../crud/model/model-crud.service';
import { Schema } from '@doclab/schema';

export interface LinkedModel {
	model: Model,
	keys: SchemaKeys;
  image?: string;
  
  // From source modelFields without filters
  sourceGroupModelFields?: any;
  fields?: string[]
}

@Injectable({
  providedIn: 'root'
})
export class ConfigModelService {
  configModels: LinkedModel[] = [];

  get models(): Model[] {
    return this.configModels.map(linkedModel => linkedModel.model);
  }

  constructor(
    private modelApi: ModelCrudService) 
  {}

  getModelDocKey(model: Model) {
		return model.docType!.name;
	}

	getModelPageKey(model: Model) {
		return model.page.toLowerCase();
	}

  containsModel(modelName: string) {
    return this.models.find(model => model.id === modelName);
  }

  // TODO: To change, not stable
  /**
   * Return case-sensitive filtered models (exact same keys)
   * @param keys 
   * @param externLinkedModels 
   * @returns 
   */
  findExactLinkedModels(keys: SchemaKeys, externLinkedModels?: LinkedModel[]) {
    let linkedModels = [];
    externLinkedModels ? linkedModels = externLinkedModels : linkedModels = this.configModels;

    return linkedModels.filter(lModel => JSON.stringify(lModel.keys) !== JSON.stringify(keys)) || [];
  }

  findLinkedModelById(id: string): LinkedModel {
    return this.configModels.filter(linkedModel => linkedModel.model.id === id)[0] ?? undefined;
  }

  /**
   * Return models with keys input
   * @param keys 
   * @param externLinkedModels 
   * @returns 
   */
  findLinkedModels(keys: SchemaKeys, level: SchemaLevel, externLinkedModels?: LinkedModel[]): LinkedModel[] {
    let linkedModels: LinkedModel[] = [];
    externLinkedModels ? linkedModels = externLinkedModels : linkedModels = this.configModels;
   
    switch (level) {
      case 'file':
        return linkedModels.filter(linkedModel => linkedModel.keys.fileKey === keys.fileKey);
      case 'document':
        return linkedModels.filter(linkedModel => 
          linkedModel.keys.fileKey === keys.fileKey
          && linkedModel.keys.docKey === keys.docKey);
      case 'page':
        return linkedModels.filter(linkedModel => 
          linkedModel.keys.fileKey === keys.fileKey
          && linkedModel.keys.docKey === keys.docKey
          && linkedModel.keys.pageKey === keys.pageKey);
      case 'field':
        return this.findExactLinkedModels(keys, linkedModels);
    }
  }

  linkModel(model: Model, keys: SchemaKeys) {
    const linkedModel: LinkedModel = { model, keys, fields: [], sourceGroupModelFields: {} };
    this.configModels.push(linkedModel);
  }

  // Unlink ---------------------------
  unlinkModelById(modelId: string) {
    this.configModels = this.configModels.filter(linkedModel => linkedModel.model.id !== modelId);
  }

  unlinkExactModelsByKeys(keys: SchemaKeys) {
    this.configModels = this.configModels.filter(linkedModel =>
      JSON.stringify(linkedModel.keys) !== JSON.stringify(keys)
    );
  }

  unlinkModels(keys: SchemaKeys, level: SchemaLevel): LinkedModel[] {   
    switch (level) {
      case 'file':
        this.configModels = this.configModels.filter(linkedModel => linkedModel.keys.fileKey !== keys.fileKey);
        break
      case 'document':
        this.configModels = this.configModels.filter(linkedModel => 
          linkedModel.keys.fileKey !== keys.fileKey
          || linkedModel.keys.docKey !== keys.docKey);
          break
      case 'page':
        this.configModels = this.configModels.filter(linkedModel => 
          linkedModel.keys.fileKey !== keys.fileKey
          || linkedModel.keys.docKey !== keys.docKey
          || linkedModel.keys.pageKey !== keys.pageKey);
          break
      case 'field':
        this.configModels = this.configModels.filter(linkedModel => 
          linkedModel.keys.fileKey !== keys.fileKey
          || linkedModel.keys.docKey !== keys.docKey
          || linkedModel.keys.pageKey !== keys.pageKey
          || linkedModel.keys.fieldKey !== keys.fieldKey);
        break
    }
    return this.configModels || [];
  }

  async modelGroups(schema: Schema) {
    let inputGroups: { [name: string]: string[] } = {};
    let modelGroups: { [name: string]: string[] } = {};

    // Generate object to put as parameters in request
    for (const fileKey in schema.files) {
      const file = schema.fileAt(fileKey)!;
      for (const docKey in file.documents) {
        const document = file.documentAt(docKey)!;
        for (const pageKey in document.pages) {
          const key = `${fileKey}_${docKey}_${pageKey}`;
          const relatedModels = this.findLinkedModels({fileKey, docKey, pageKey}, 'page');
          inputGroups[key] = relatedModels.map(linkedModel => linkedModel.model.id);
        }
      }
    }

    console.warn(inputGroups)
    
    if (Object.keys(inputGroups).length === 0) return

    modelGroups = await this.getAllComparedModelFields(inputGroups);

    console.warn('modelGroups', modelGroups, 'input', inputGroups, 'configModels', this.configModels)

    // Attribute and edit fields related to linked models in schemaNode
    for (const linkedModel of this.configModels) {
      linkedModel.fields = [];
      const key = `${linkedModel.keys.fileKey}_${linkedModel.keys.docKey}_${linkedModel.keys.pageKey}`;
      linkedModel.sourceGroupModelFields = modelGroups[key];
      for (const modelFieldKey in linkedModel.sourceGroupModelFields) {
        if (linkedModel.sourceGroupModelFields[modelFieldKey].includes(linkedModel.model.id)) {
          linkedModel.fields.push(...[modelFieldKey]);
        }
      }
    }
  }

  async getAllComparedModelFields(modelGroups: {[name: string]: string[]}) {
    let fields = {};
		fields = await firstValueFrom(this.modelApi.getFieldsByModelGroups(modelGroups));
		return fields;
  }

  async getModelFields(model: Model): Promise<ModelField[]> {
		let fields: ModelField[] = [];
		try {
			fields = await firstValueFrom(this.modelApi.getModelFieldsById(model.id));
		} catch (error) {
			console.warn('Erreur fields', (error as Error).message);
		}
		return fields;
	}

  getModelGroupFromKeys(keys: SchemaKeys) {
    if (keys.fileKey && keys.docKey && keys.pageKey) {
      return `${keys.fileKey}_${keys.docKey}_${keys.pageKey}`;
    }
    return undefined;
  }
}
