import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { DialogModelsDialogComponent } from 'src/app/home/documents/dialog-models-dialog/dialog-models-dialog.component';
import { BoxData, BoxType, Model } from 'src/app/models/model/model.model';
import { KonvaService } from 'src/app/services/konva/konva.service';
import { ModelCrudService } from 'src/app/services/crud/model/model-crud.service';
import { ImageCrudService } from 'src/app/services/crud/model/image-crud.service';
import { FULL_SCREEN_DIALOG_CONFIG } from 'src/app/models/dialog.model';
import { ModelImages } from 'src/app/models/model/image.model';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'app-document-editor',
  templateUrl: './document-editor.component.html',
  styleUrl: './document-editor.component.scss'
})
export class DocumentEditorComponent implements OnInit, OnChanges {
  @ViewChild('menu') menu!: ElementRef<HTMLDivElement>;

  @Input() document?: Model;
  @Input({transform: coerceBooleanProperty}) previewMode: boolean = false;
  @Input({transform: coerceBooleanProperty}) readonlyMode: boolean = false;

  documentBeforeEdition?: Model

  selectedBoxType?: BoxType;
  
  imageUrl: string = '';

  selectedBox?: BoxData;

  constructor(
    public konvaService: KonvaService, 
    private matDialog: MatDialog,
    private modelApi: ModelCrudService,
    private imageApi: ImageCrudService) 
  {}

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['document'] && this.document) {
      await this.fetch(this.document.id);
    }

    if (changes['previewMode'] && this.previewMode) {
      this.readonlyMode = true;
    }
  }

  async ngOnInit() {
    if (!this.previewMode) {
      await this.searchModel();
    }
    
    this.konvaService.selectedBox$.subscribe((box: BoxData | undefined) => {
      this.selectedBox = box;
    });
  }

  async searchModel() {
    const dialogRef = this.matDialog.open(DialogModelsDialogComponent, { 
      data: { singleSelection: true }, ...FULL_SCREEN_DIALOG_CONFIG, disableClose: true
     });
    dialogRef.afterClosed().subscribe(async (models: Model[]) => {
      // Get Model from selection
      if (models && models[0]) {
        await this.fetch(models[0].id);
      }
    });
  }

  async fetch(modelId: string) {
    this.document = await firstValueFrom(this.modelApi.getModelById(modelId));
    this.documentBeforeEdition = this.document;
    console.warn(this.document)
    await this.loadImage(modelId);
    this.konvaService.initStage('scene', this.menu, { model: this.document, image: this.imageUrl });
  }

  async loadImage(modelId: string) {
    const modelImages: ModelImages = await firstValueFrom(this.modelApi.getModelImagesById(modelId));
    const mainImageId = modelImages.images.find(image => image.type === 'main');
    if (mainImageId) {
      this.imageUrl = await firstValueFrom(this.imageApi.getImageById(mainImageId.id));
    }
  }

  onSelectChange(event: any) {
    this.selectedBoxType = event.value;
    console.warn(this.selectedBoxType)
    this.konvaService.createBox(this.selectedBoxType!);
  }

  // Méthode appelée lorsque l'utilisateur clique sur la scène pour ajouter une boîte
  onStageClick(event: MouseEvent): void {
    // if (this.selectedBoxType) {
    //   this.konvaService.addBox(event.offsetX, event.offsetY, this.selectedBoxType);
    // }
  }

  updateBox(newBox: BoxData) {
    this.konvaService.updatedBoxSubject.next(newBox);
  }

  save() {

  }

  compute() {

  }

  create() {

  }

  updateDocument(model: Model) {
    if (this.document) {
      Object.assign(this.document, model);
    }
  }
}
