<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title i18n>
				<mat-icon>description</mat-icon>
				@if (document) {
					Document
				} @else {
					Pending...
				}				
			</mat-panel-title>
    </mat-expansion-panel-header>

		<!-- <mat-list>
			<mat-list-item>
				<mat-icon matListItemIcon>add_box</mat-icon>
				<span matListItemTitle i18n>ID: {{document.id ?? 'N/A'}}</span>
				<span matListItemLine i18n>Created: {{document.creationDate | date}}</span>
				<span matListItemLine i18n>Saved: {{ document.modificationDate | date}}</span>
				<span matListItemLine i18n>Saved: {{ document.modificationDate | date}}</span>
				<span matListItemLine i18n>Saved: {{ document.modificationDate | date}}</span>
				<span matListItemLine i18n>Saved: {{ document.modificationDate | date}}</span>
			</mat-list-item>

			<mat-list-item>
				<mat-icon matListItemIcon>folder</mat-icon>
				<div matListItemTitle>{{folder.name}}</div>
				<div matListItemLine>{{folder.updated | date}}</div>
			</mat-list-item>
		</mat-list> -->

		<form [formGroup]="modelForm">

    <mat-form-field appearance="outline">
      <mat-label floatLabel="always" i18n>ID</mat-label>
      <input matInput [value]="document?.id ?? ''" readonly/>
    </mat-form-field>

		<mat-form-field appearance="outline">
      <mat-label floatLabel="always" i18n>Creation Date</mat-label>
      <input matInput [value]="document?.creationDate ?? '' | date: 'dd/MM/yyyy'" readonly/>
    </mat-form-field>

		<mat-form-field appearance="outline">
      <mat-label floatLabel="always" i18n>Modification Date</mat-label>
      <input matInput [value]="document?.modificationDate ?? '' | date: 'dd/MM/yyyy'" readonly/>
    </mat-form-field>

		<mat-form-field appearance="outline">
      <mat-label floatLabel="always" i18n>Name</mat-label>
      <input matInput formControlName="name"/>
    </mat-form-field>

		<mat-form-field appearance="outline">
		<mat-label floatLabel="always" i18n>Page</mat-label>
      <input matInput formControlName="page"/>
    </mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label floatLabel="always" i18n>Type</mat-label>
			<input matInput formControlName="type"/>
		</mat-form-field>

		<mat-form-field>
			<mat-label floatLabel="always" i18n>Language</mat-label>
			<mat-select formControlName="languageId" name="language">
				@for (language of languages$ | async; track language.id) {
					<mat-option [value]="language.id">{{language.name}} ({{language.code}})</mat-option>
				}
			</mat-select>
		</mat-form-field>

		<mat-form-field>
			<mat-label floatLabel="always" i18n>Category</mat-label>
			<mat-select formControlName="docTypeId" name="docType">
				@for (type of types$ | async; track type.id) {
					<mat-option [value]="type.id">{{type.name}}</mat-option>
				}
			</mat-select>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label floatLabel="always" i18n>Validity Date</mat-label>
			<input matInput formControlName="validityDate" [matDatepicker]="datepicker"/>
			<mat-hint>DD/MM/YYYY</mat-hint>
			<mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
			<mat-datepicker #datepicker>
				<mat-datepicker-actions>
					<button mat-button matDatepickerCancel>Cancel</button>
					<button mat-raised-button matDatepickerApply>Apply</button>
				</mat-datepicker-actions>
			</mat-datepicker>
		</mat-form-field>

		<mat-slide-toggle formControlName="mobile" i18n>Mobile</mat-slide-toggle>
		<mat-slide-toggle formControlName="public" i18n>Public</mat-slide-toggle>
		<mat-slide-toggle formControlName="shiny" i18n>Shiny document</mat-slide-toggle>
		</form>
  </mat-expansion-panel>
  <mat-expansion-panel #imagePanel (opened)="updateImages()">
    <mat-expansion-panel-header>
      <mat-panel-title i18n> Images </mat-panel-title>
    </mat-expansion-panel-header>

		<div class="container-image">
			<span class="mat-title-small">Main</span>
			<div class="images">
				<div class="options mat-elevation-z8">
					<button mat-icon-button (click)="triggerFileInput('main')">
						<mat-icon>add</mat-icon>
					</button>
					<button mat-icon-button [disabled]="!mainImage" (click)="removeMainImage()">
						<mat-icon>delete</mat-icon>
					</button>
				</div>
				<img [src]="mainImage" class="selectable"/>
			</div>
			<mat-divider></mat-divider>
			<span class="mat-title-small">Alternates</span>
			<div class="images">
				<div class="options mat-elevation-z8">
					<button mat-icon-button (click)="triggerFileInput('alternative')">
						<mat-icon>add</mat-icon>
					</button>
					<button mat-icon-button [disabled]="!selectedAltImage" (click)="removeSelectedAltImage()">
						<mat-icon>delete</mat-icon>
					</button>
				</div>
				@for (image of altImages; track $index) {
					<img [src]="image" class="selectable" 
					(click)="selectedAltImage = $index" 
					[class.selected]="selectedAltImage === $index"/>
				}
			</div>
			<mat-divider></mat-divider>
			<span class="mat-title-small">Tests</span>
			<div class="images">
				<div class="options mat-elevation-z8">
					<button mat-icon-button (click)="triggerFileInput('test')">
						<mat-icon>add</mat-icon>
					</button>
					<button mat-icon-button [disabled]="!selectedTestImage" (click)="removeSelectedTestImage()">
						<mat-icon>delete</mat-icon>
					</button>
				</div>
				@for (image of testImages; track $index) {
					<img [src]="image" class="selectable"
					(click)="selectedTestImage = $index"
					[class.selected]="selectedTestImage === $index"/>
				}
			</div>
		</div>
  </mat-expansion-panel>
</mat-accordion>

<input #fileInput type="file" [multiple]="typeImageBeingImported !== 'main'" 
name="file" accept="image/png, image/jpeg" (change)="onSelectedFiles($event)"
	style="display: none" />