import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { firstValueFrom, map, Observable, startWith, switchMap } from 'rxjs';
import { ImageType, ModelImage } from 'src/app/models/model/image.model';
import { Language } from 'src/app/models/model/language.model';
import { DocType, Model } from 'src/app/models/model/model.model';
import { DocTypeModelCrudService } from 'src/app/services/crud/model/doc-type-crud.service';
import { ImageCrudService } from 'src/app/services/crud/model/image-crud.service';
import { LanguageModelCrudService } from 'src/app/services/crud/model/language-crud.service';

@Component({
  selector: 'app-document-panel',
  templateUrl: './document-panel.component.html',
  styleUrl: './document-panel.component.scss'
})
export class DocumentPanelComponent implements OnInit, OnChanges {
  @ViewChild('imagePanel') imagePanel!: MatExpansionPanel;
  @ViewChild('fileInput', { static: true })
  fileInput!: ElementRef<HTMLInputElement>;

  @Input() document?: Model;
  @Output() documentChange = new EventEmitter<Model>();

  mainImage: string = '';
  mainImageBeingImported: boolean = false;

  altImages: string[] = [];
  selectedAltImage: number = 0;
  altImageBeingImported: boolean = false;

  testImages: string[] = [];
  selectedTestImage: number = 0;
  testImageBeingImported: boolean = false;

  typeImageBeingImported?: ImageType;
  
  modelForm!: FormGroup;

  // * Lists of choices
  languages$?: Observable<Language[]>;  
  types$?: Observable<DocType[]>;

  constructor(
    private fb: FormBuilder,
    private imageApi: ImageCrudService,
    private docTypeApi: DocTypeModelCrudService,
    private languageApi: LanguageModelCrudService
  ) {}

  async ngOnInit() {
    this.types$ = this.docTypeApi.getdocTypes();
    this.languages$ = this.languageApi.getLanguages();
    
    this.initForms();

    this.modelForm.valueChanges.subscribe((value: any) => {
      this.documentChange.emit(value);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['document']) {
      this.updateForm();

      if (this.imagePanel?.expanded) {
        this.updateImages();
      }
    }
  }
  
  initForms() {
    this.modelForm = this.fb.group({
      name: [''],
      page: [''],
      type: [''],
      languageId: [],
      docTypeId: [],
      validityDate: [],
      mobile: [false],
      public: [false],
      shiny: [false],
      // version: [],
    });
  }
  
  updateForm() {
    if (this.modelForm && this.document) {
      this.modelForm.patchValue({
        languageId: this.document.language!.id,
        page: this.document.page,
        docTypeId: this.document.docType!.id,
        name: this.document.name,
        type: this.document.type ?? '',
        validityDate: this.document.validityDate,
        shiny: this.document.shiny,
      });
    }
  }

  updateImages() {
    if (this.document) {
      this.mainImage = '';
      this.altImages = [];
      this.testImages = [];

      const imagePromises = this.document.images!.map((image) => this.loadImage(image));

      Promise.allSettled(imagePromises).then(() => {});
    }
  }

  async loadImage(image: ModelImage) {
    try {
      const importedImage = await firstValueFrom(this.imageApi.getImageById(image.id));
      switch (image.type) {
        case 'main':
          this.mainImage = importedImage;
        break;
        case 'alternative':
          this.altImages.push(importedImage);
        break;
        case 'test':
          this.testImages.push(importedImage);
        break;
      }
    } catch (error) {
      
    }
  }

  removeMainImage() {
    this.mainImage = '';
  }

  removeSelectedAltImage() {
    this.altImages.splice(this.selectedAltImage, 1);
    this.selectedAltImage = 0;
  }

  removeSelectedTestImage() {
    this.testImages.splice(this.selectedTestImage, 1);
    this.selectedTestImage = 0;
  }

  triggerFileInput(type: ImageType) {
    this.typeImageBeingImported = type;
    // Reset the value property to allow select multiple times the same file
		this.fileInput.nativeElement.value = '';
			// Load/Prepare files and then start
		this.fileInput.nativeElement.click();
  }

  async onSelectedFiles(event: any) {
		const fileList = event?.target?.files as FileList;
    const files: File[] = this.getAllImageTypes(fileList) ?? [];
		if (files.length === 0) {
			return;
		}

    const imagePromises = files.map((file) => this.addImage(file));

    Promise.allSettled(imagePromises).then(() => {});
	}

  private async addImage(file: File): Promise<void> {
    const reader = new FileReader();
  
    const base64String = await new Promise<string>((resolve, reject) => {
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });

    switch (this.typeImageBeingImported) {
      case 'main':
        this.mainImage = base64String;
        break;
      case 'alternative': 
        this.altImages.push(base64String);
        break;
      case 'test': 
      this.testImages.push(base64String);
        break;
    }
  }

  getAllImageTypes(fileList: FileList): File[] | null {
    const images: File[] = [];

    for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        if (file.type === "image/png" || file.type === "image/jpeg") {
          images.push(file);
        } else {
            return null;
        }
    }
    return images;
  }
}
