import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environment';
import { Buffer } from 'buffer'
import { EnvironmentService } from '../../environment.service';

@Injectable({
  providedIn: 'root'
})
export class ImageCrudService {
  private baseUrl = `${this.environment.apiUrl}/image`;

  constructor(private http: HttpClient, private environment: EnvironmentService) {}

  // Get
  getImageById(imageId: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/${imageId}`, { responseType: 'arraybuffer' }).pipe(
      map((arrayBuffer) => {
        const base64 = Buffer.from(arrayBuffer).toString('base64');
        return `data:image/png;base64,${base64}`
      })
    );
  }

	// Put
  editImage(image: any): Observable<any> {
    return this.http.put(`${this.baseUrl}`, image);
  }
	// Get
  deleteImageById(imageId: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${imageId}`);
  }
}
