import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HeaderComponent } from './layouts/header/header.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthorizationInterceptor } from './interceptors/authorization.interceptor';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { RefreshInterceptor } from './interceptors/refresh.interceptor';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { EnvironmentService } from './services/environment.service';
import { DialogMigrationLoaderDialogComponent } from './layouts/header/dialog-migration-loader-dialog/dialog-migration-loader-dialog.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: 'https://keycloak-test.wizidee.fr/auth',
        realm: 'bff',
        clientId: 'keycloak-angular',
      },
      initOptions: {
        onLoad: 'check-sso',
      },
    });
}

export function initializeApp(envService: EnvironmentService) {
  return (): Promise<any> => {
    return new Promise((resolve, reject) => {
      envService.initBaseUrl();
      resolve(true);
    });
  };
}

@NgModule({
  declarations: [
    AppComponent,
    
    DashboardComponent,
    UserSettingsComponent,
    HeaderComponent,
    DialogMigrationLoaderDialogComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,

    KeycloakAngularModule,
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [EnvironmentService]
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: RefreshInterceptor },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic' }}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
