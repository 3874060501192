import { Injectable } from '@angular/core';
import Konva from 'konva';
import { Group } from 'konva/lib/Group';
import { BehaviorSubject, map } from 'rxjs';
import { BoxData, Model } from 'src/app/models/model/model.model';

@Injectable({
  providedIn: 'root'
})
export class EditorStateService {
	public modelSubject: BehaviorSubject<Model | undefined> = new BehaviorSubject<any>(undefined);
  model$ = this.modelSubject.asObservable().pipe(map((data) => {
  }));

	// Model
  public selectedBoxSubject: BehaviorSubject<Konva.Group | undefined> = new BehaviorSubject<Konva.Group | undefined>(undefined);
  selectedBox$ = this.selectedBoxSubject.asObservable().pipe(map((group: Konva.Group | undefined) => {
    if (!group) return
		return <BoxData>{
      name: group?.attrs.label,
      type: group?.attrs.type,
      x: group?.attrs.x,
      y: group?.attrs.y,
      
      width: group?.attrs.width,
      height: group?.attrs.height,
    };
	}));

  // public updatedBoxSubject: BehaviorSubject<BoxData> = new BehaviorSubject<any>(undefined);
  // updatedBox$ = this.updatedBoxSubject.asObservable().pipe(map((boxData) => {
  //   const updatedModel = {...this.modelSubject.getValue(), ...boxData}
  //   this.modelSubject.next(updatedModel);
  // }));

  // selectBox(group: Group) {

  // }
}