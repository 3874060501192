@if (showTitle) {
	<span class="title" i18n>Models</span>
}
@if (!showImagesOnly || removeShowableColumns) {
	<div class="button-bar">
		<mat-chip-listbox aria-label="Filters fields" multiple>
			<mat-chip-option 
			[selected]="displayedColumns.includes('page')"
			(click)="toggleColumn('page')">
			Page
		</mat-chip-option>
		<mat-chip-option 
			[selected]="displayedColumns.includes('type')"
			(click)="toggleColumn('type')">
			Type
		</mat-chip-option>
		<mat-chip-option 
			[selected]="displayedColumns.includes('category')"
			(click)="toggleColumn('category')">
			Category
		</mat-chip-option>
		<mat-chip-option 
			[selected]="displayedColumns.includes('language')"
			(click)="toggleColumn('language')">
			Language
		</mat-chip-option>
		<mat-chip-option 
			[selected]="displayedColumns.includes('popularity')"
			(click)="toggleColumn('popularity')">
			Popularity
		</mat-chip-option>
		<mat-chip-option 
			[selected]="displayedColumns.includes('public')"
			(click)="toggleColumn('public')">
			Public
		</mat-chip-option>
		<mat-chip-option 
			[selected]="displayedColumns.includes('createdAt')"
			(click)="toggleColumn('createdAt')">
			Created at
		</mat-chip-option>
		<mat-chip-option 
			[selected]="displayedColumns.includes('updatedAt')"
			(click)="toggleColumn('updatedAt')">
			Updated at
		</mat-chip-option>
		</mat-chip-listbox>
	</div>
}

@if (!removeFilter) {
	<mat-form-field subscriptSizing="dynamic">
		<mat-label i18n>Filter</mat-label>
		<input
			matInput
			(keyup)="applyFilter($event)"
			placeholder="Enter your search..."
			i18n-placeholder
			#input>
		<mat-icon matPrefix>search</mat-icon>
	</mat-form-field>
}

<section class="table-container">
	@if (loading) {
		<mat-progress-bar mode="query"></mat-progress-bar>
	}
	<table mat-table [dataSource]="dataSource" matSort>

		<ng-container matColumnDef="select" sticky>
			<th mat-header-cell *matHeaderCellDef>
				<mat-checkbox (change)="$event ? toggleAllRows() : null"
											[checked]="selection.hasValue() && isAllSelected()"
											[indeterminate]="selection.hasValue() && !isAllSelected()"
											[aria-label]="checkboxLabel()">
				</mat-checkbox>
			</th>
			<td mat-cell *matCellDef="let row">
				<mat-checkbox (click)="$event.stopPropagation()"
											(change)="$event ? toggle(row) : null"
											[checked]="selection.isSelected(row)"
											[aria-label]="checkboxLabel(row)">
				</mat-checkbox>
			</td>
		</ng-container>

		<ng-container matColumnDef="name" sticky>
			<th mat-header-cell *matHeaderCellDef mat-sort-header i18n> Name </th>
			<td mat-cell *matCellDef="let element">
				<span class="layout">
					@if (element.public) {
						<mat-icon class="small discreet public" matTooltip="Public">public</mat-icon>
					}
					@if (element.popularity >= 75) {
						<mat-icon class="small discreet public" matTooltip="Popular">favorite</mat-icon>
					}
				</span>
				
				 {{element.name}} 
			</td>
		</ng-container>

			<ng-container matColumnDef="page">
				<th mat-header-cell *matHeaderCellDef mat-sort-header i18n> Page </th>
				<td mat-cell *matCellDef="let element"> {{element.page}} </td>
			</ng-container>

			<ng-container matColumnDef="type">
				<th mat-header-cell *matHeaderCellDef mat-sort-header i18n> Type </th>
				<td mat-cell *matCellDef="let element"> 
					@if (element.type) {
						<mat-chip>{{element.type}}</mat-chip>
					}
				</td>
			</ng-container>

			<ng-container matColumnDef="category">
				<th mat-header-cell *matHeaderCellDef mat-sort-header i18n> Category </th>
				<td mat-cell *matCellDef="let element"> 
					@if (element.category) {
						<mat-chip>{{element.category}}</mat-chip>
					}
				</td>
			</ng-container>

			<ng-container matColumnDef="language">
				<th mat-header-cell *matHeaderCellDef mat-sort-header i18n> Lang. </th>
				<td mat-cell *matCellDef="let element"> {{element.language}} </td>
			</ng-container>

			<ng-container matColumnDef="popularity">
				<th mat-header-cell *matHeaderCellDef mat-sort-header i18n> Pop. </th>
				<td mat-cell *matCellDef="let element">
					@if (element.popularity) {
						{{element.popularity}}% 
					}
				</td>
			</ng-container>

			<ng-container matColumnDef="public">
				<th mat-header-cell *matHeaderCellDef mat-sort-header i18n> Public </th>
				<td mat-cell *matCellDef="let element">
					@if (element.public) {
						<mat-icon>check_circle</mat-icon>
					}
				</td>
			</ng-container>

			<ng-container matColumnDef="updatedAt">
				<th mat-header-cell *matHeaderCellDef mat-sort-header i18n> Updated at </th>
				<td mat-cell *matCellDef="let element"> {{element.updatedAt | date: 'dd/MM/YYYY hh:mm'}} </td>
			</ng-container>

			<ng-container matColumnDef="createdAt">
				<th mat-header-cell *matHeaderCellDef mat-sort-header i18n> Created at </th>
				<td mat-cell *matCellDef="let element"> {{element.createdAt | date: 'dd/MM/YYYY hh:mm'}} </td>
			</ng-container>

			<ng-container matColumnDef="image" stickyEnd>
				<th mat-header-cell *matHeaderCellDef mat-sort-header i18n> Preview </th>
				<td #imageCell mat-cell *matCellDef="let element" [attr.model-id]="element.id">
					@if (element.loadingImage) {
						<mat-spinner diameter="20" strokeWidth="3"></mat-spinner>
					}
					@if (element.image) {
						<div class="container-preview">
							<button mat-icon-button class="show-preview small discreet selectable" (click)="showImage(element); $event.stopPropagation()">
								<mat-icon class="icon-preview">visibility</mat-icon>
							</button>
							<img [src]="element.image"/>
						</div>
						
					}
					@if (showImagesOnly) {
						<span class="image-name">{{ element.name }}</span>
					}
				</td>
			</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns;"
		[class.selectable]="singleSelection"
		[class.selected]="singleSelection && selection.isSelected(row)"
		(click)="toggle(row)">
		</tr>
	</table>

	@if (!removePaginator) {
		<mat-paginator 
		[pageSizeOptions]="[5, 10, 20]" 
		aria-label="Select page of models"></mat-paginator>
	}
</section>

@if (!dynamicSelection) {
	<div class="buttons">
		<button mat-raised-button (click)="sendModels()">
			<mat-icon>send</mat-icon>
			<span i18n>Send</span>
		</button>
	</div>
}

@if (showSelection) {
	<span class="selection">{{selection.selected.length}} <span i18n>selected model(s)</span></span>
}