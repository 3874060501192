import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ModelField } from 'src/app/models/model/field.model';
import { Model } from 'src/app/models/model/model.model';
import { EnvironmentService } from '../../environment.service';
import { ModelImages } from 'src/app/models/model/image.model';

@Injectable({
  providedIn: 'root'
})
export class ModelCrudService {
  private baseUrl = `${this.environment.apiUrl}/model`;

  constructor(private http: HttpClient, private environment: EnvironmentService) { }

  getModels(params?: Partial<{ categoryId: number, languageId: number, page: string, docTypeId: number }>): Observable<any> {
    const httpParams = new HttpParams({ fromObject: params });
    return this.http.get(this.baseUrl, { params: httpParams });
  };
  
  getModelById(modelId: string): Observable<Model> {
    return this.http.get<Model>(`${this.baseUrl}/${modelId}`);
  }

  getFieldsByModelIds(modelIds: string[]): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/getFieldsByModels`, { modelIds });
  }

  getFieldsByModelGroups(modelIds: {[key: string]: string[]}): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/getFieldsByModels`, modelIds);
  }

  getModelFieldsById(modelId: string): Observable<any> {
    return this.http.get<ModelField[]>(`${this.baseUrl}/${modelId}/field`);
  }

  getModelImagesById(modelId: string): Observable<ModelImages> {
    return this.http.get<ModelImages>(`${this.baseUrl}/${modelId}/image`);
  }

  getModelHistoryById(modelId: string): Observable<Model> {
    return this.http.get<Model>(`${this.baseUrl}/${modelId}/history`);
  }

  /**
   * Import some models to manager.
   * @param files Blob
   * @param overwrite True by default
   * @returns Array with no-migrated model Ids, code 210 (partially passed) if some models wasn't migrated
   * @returns Code 201 if all models are passed
   */
  migrate(file: File, overwrite: boolean = true): Observable<any> {
    const blob = new Blob([file], {type: 'application/json'});
    const formdata = new FormData();
    formdata.append('file', blob);
    formdata.append('overwrite', overwrite.toString());
    return this.http.post(`${this.baseUrl}/migrate`, formdata, { observe: 'response', responseType: 'text' }).pipe(
      map((response) => {
        return response.status;
      })
    );
    
  }

  // Put
  editModel(model: Model): Observable<any> {
    return this.http.put<Model>(`${this.baseUrl}/${model.id}`, model);
  }
  // Delete
  deleteModelById(modelId: number): Observable<any> {
    return this.http.delete<void>(`${this.baseUrl}/${modelId}`);
  }
}
