import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Config } from 'src/app/models/config.model';
import { Stage } from 'src/app/models/stage.model';
import { TreeService } from 'src/app/services/tree.service';
import { DialogConfirmTransferConfigDialogComponent } from '../dialogs/duplicate/dialog-confirm-transfer-config-dialog/dialog-confirm-transfer-config-dialog.component';
import { DialogDeleteConfigDialogComponent } from '../dialogs/delete/dialog-delete-config-dialog/dialog-delete-config-dialog.component';
import { Project } from 'src/app/models/project.model';
import { DialogConfirmDeployConfigDialogComponent } from '../dialogs/edit/dialog-confirm-deploy-config-dialog/dialog-confirm-deploy-config-dialog.component';

@Component({
  selector: 'app-config-card',
  templateUrl: './config-card.component.html',
  styleUrl: './config-card.component.scss'
})
export class ConfigCardComponent implements OnInit {
  @Input() project?: Project;
  @Input() config!: Config;
  @Input() stage?: Stage;

  @Output() stateChanged = new EventEmitter<boolean>();

  @Input() availableStages: Stage[] = [];
  @Input({ transform: coerceBooleanProperty }) disableContextMenu = false;
  @Input({ transform: coerceBooleanProperty }) showModificationDate = false;
  @Input({ transform: coerceBooleanProperty }) showDeploymentDate = false;
  @Input({ transform: coerceBooleanProperty }) showStageColor = false;
  @Input({ transform: coerceBooleanProperty }) showDeploymentIndicator = false;
 
  deployed: boolean = false;

  constructor(
    private treeService: TreeService,
    private router: Router,
    private matDialog: MatDialog,
    ) {}
  
  ngOnInit(): void {
    if (this.config.deployDate) {
      this.deployed = true;
    }
  }

  navigate() {
    const node = this.treeService.getNodeById('config', this.config.id)!;
    this.router.navigate(['config', node.id]);
  }

  copyTo(stage: Stage) {
    const dialogRef = this.matDialog.open(DialogConfirmTransferConfigDialogComponent, { data: {
      stage,
      configName: this.config.name,
      configId: this.config.id,
      projectParentName: this.project?.name
    }});
    dialogRef.afterClosed().subscribe(async (copied: boolean) => {
      if (copied) {
        this.stateChanged.emit(copied);
      }
    });
  }

  remove() {
    const dialogRef = this.matDialog.open(DialogDeleteConfigDialogComponent, { data: {
      stage: this.stage,
      configName: this.config.name,
      configId: this.config.id,
      projectParentName: this.project?.name 
    }});
    dialogRef.afterClosed().subscribe(async (removed: boolean) => {
      if (removed) {
        this.stateChanged.emit(removed);
      }
    });
  }
  
  deploy() {
    const dialogRef = this.matDialog.open(DialogConfirmDeployConfigDialogComponent, { data: {
      stage: this.stage,
      configName: this.config.name,
      configId: this.config.id
    }});
    dialogRef.afterClosed().subscribe(async (deployed: boolean) => {
      if (deployed) {
        this.stateChanged.emit(deployed);
      }
    })
  }
}
