import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ToastService } from './services/toast.service';
import { MessageService } from 'primeng/api';

const routes: Routes = [
	{  path: 'dashboard', component: DashboardComponent },
	{  path: 'profile/settings', component: UserSettingsComponent },
	{  path: 'notifications', component: NotificationsComponent },
	{  path: 'admin',
		loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) 
	},
	{ 
		path: '',
		loadChildren: () => import('./home/home.module').then(m => m.HomeModule) 
	},
	{ path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule],
  providers: [ToastService, MessageService]
})
export class AppRoutingModule { }
