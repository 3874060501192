import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { getBoxStyles, getBoxDescription, BOX_CONFIGS } from 'src/app/models/model/model.model';
import { KonvaService } from 'src/app/services/konva/konva.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss'
})
export class ToolbarComponent {
  @Input({transform: coerceBooleanProperty}) canToggle: boolean = false;
  @Output() createBox = new EventEmitter<string>();

  toggle: boolean = true;

  boxKeys = Object.keys(BOX_CONFIGS);

  constructor() {}

  getBoxStyles(key: string) {
    return getBoxStyles(key);
  }

  getBoxDescription(key: string) {
    return getBoxDescription(key);
  }

  emitNewBox(key: string) {
    this.createBox.emit(key);
  }
}
