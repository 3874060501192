import { ElementRef, Injectable, Renderer2, RendererFactory2, inject } from '@angular/core';
import Konva from 'konva';
import { RectConfig } from 'konva/lib/shapes/Rect';
import { KonvaEventService } from './konva-event.service';
import { KonvaHistoryService } from './konva-history.service';
import { BoxType, BOX_CONFIGS, Model, BoxData, RawBox } from 'src/app/models/model/model.model';
import { KonvaMenuService } from './konva-menu.service';
import { LEFT_CLICK } from 'src/app/models/konva.model';
import { KonvaRenameBoxService } from './konva-rename-box.service';
import { BehaviorSubject, map } from 'rxjs';
import { EditorStateService } from './editor-state.service';

export type CropEventType = 'start' | 'stop' | 'doing' | 'done' | 'cancel' | 'ok' | 'ko' | 'none' | 'dragStart' | 'dragEnd';
export type DirectionType = 'right' | 'left' | 'top' | 'bottom';
export type ZoomType = 'fitToSize' | 'fitToHeight' | 'fitToWidth' | 'oneToOne' | 'zoomIn' | 'zoomOut' | 'none';
export type RotationType = 'cw' | 'ccw';
export declare type GravityType = 'NW' | 'NC' | 'NE' | 'CW' | 'CC' | 'CE' | 'SW' | 'SC' | 'SE';

interface ModelData {
  model: Model,
  image: string
}

@Injectable({
  providedIn: 'root'
})
export class KonvaService {
  public updatedBoxSubject: BehaviorSubject<BoxData | undefined> = new BehaviorSubject<BoxData | undefined>(undefined);
  updatedBox$ = this.updatedBoxSubject.asObservable();

  // Connector to get box selection
  public selectedBoxSubject: BehaviorSubject<Konva.Group | undefined> = new BehaviorSubject<Konva.Group | undefined>(undefined);
	// selectedBox$ = this.selectedBoxSubject.asObservable();
  selectedBox$ = this.selectedBoxSubject.asObservable().pipe(map((group: Konva.Group | undefined) => {
    if (!group) return
		return <BoxData>{
      name: group?.attrs.label,
      type: group?.attrs.type,
      x: group?.attrs.x,
      y: group?.attrs.y,
      width: group?.attrs.width,
      height: group?.attrs.height,
    };
	}));
  
  model!: Model;

  stageContainer!: ElementRef;
  stage!: Konva.Stage;
  layer!: Konva.Layer;
  transformer!: Konva.Transformer;
  image!: Konva.Image;

  // Options on selection (right click)
  menu!: ElementRef;
  activeGuidLines: boolean = true;

  private renderer: Renderer2;
  readonly scaleBy = 1.2;
  readonly moveBy = 200

  constructor(
    private konvaEvent: KonvaEventService,
    private konvaHistory: KonvaHistoryService,
    private konvaMenu: KonvaMenuService,
    private konvaRenameBox: KonvaRenameBoxService,
    private editorState: EditorStateService) {
    this.renderer = inject(RendererFactory2).createRenderer(null, null);

    this.updatedBoxSubject.subscribe((boxData: BoxData | undefined) => {
      this.updateSelection(boxData);
    });
   }

  async initStage(containerId: string, menu: ElementRef, modelData?: ModelData) {
    if (this.stage) this.stage.destroy();
    this.menu = menu;

    this.stage = new Konva.Stage({
      container: containerId,
      width: window.innerWidth,
      height: window.innerHeight,
      draggable: true
    });

    // this.stageContainer = this.renderer.selectRootElement(containerId, true);

    this.stage.on('wheel', (event) => {
      this.konvaEvent.toggleZoom(event, this.stage, this.scaleBy);
    });

    this.stage.on('click', (event) => {
      if (event.evt.button === LEFT_CLICK) {
        this.konvaRenameBox.closeEditionFrame(this.stage);
        this.konvaMenu.closeMenu(event, this.menu);
      }
    })

    this.layer = new Konva.Layer();
    this.stage.add(this.layer);

    if (modelData) {
      await this.generateModel(modelData)
    }
   
    this.transformer = new Konva.Transformer({
      borderStrokeWidth: 0, 
      anchorSize: 10,
      rotateEnabled: false,
      anchorStroke: 'gray',
      anchorStrokeWidth: 1,
      ignoreStroke: true,
    });
    this.layer.add(this.transformer);
    this.transformer.nodes([]);

    this.transformer.on('transform', () => {
      this.layer.batchDraw();
    });
    this.konvaHistory.save(this.layer);
  }

  private getRectConfigByType(type: BoxType | string): Partial<RectConfig> {
    return BOX_CONFIGS[type] || BOX_CONFIGS['default'];
  }

  private getBoxConfigByGeometry(rawBox: RawBox): Partial<RectConfig> {
    return {
      x: rawBox.x,
      y: rawBox.y,
      width: rawBox.width,
      height: rawBox.height
    }
  }
  
  public createBox(type: BoxType | string, options?: { fromData?: {rawBox: RawBox, index: number}, disableUndoRedo?: boolean }) {
    const group = new Konva.Group({
      x: 0,
      y: 0,
      width: this.image.width() / 3,
      height: this.image.height() / 3,
      name: 'box',
      selected: false,
      type,
    });
    if (options?.fromData) {
      group.setAttr('label', options.fromData.rawBox.name);
      group.setZIndex(options.fromData.rawBox.zIndex);
      group.setAttrs({...this.getBoxConfigByGeometry(options.fromData.rawBox)});
    }
    const rect = new Konva.Rect({
      ...this.getRectConfigByType(type),
      x: 0,
      y: 0,
      width: group.width(),
      height: group.height(),
      cornerRadius: 5,
      strokeScaleEnabled: false,
      hitStrokeWidth: 10
    });

    const text = new Konva.Text({
      text: type,
      fontSize: 18 / this.stage.scaleX(),
      padding: 5,
      ellipsis: true,
      width: group.width() - 5,
      height: 25,
      // Dont work with Text : https://github.com/konvajs/konva/issues/1370
      strokeScaleEnabled: false,
      fill: 'black',
      fontStyle: 'bold',
    });
    if (options?.fromData) {
      text.setText(options.fromData.rawBox.name);
    }
    this.defineBoxListeners(group, rect, text, {disableUndoRedo: options?.disableUndoRedo ?? false});

    group.add(rect, text);
    this.layer.add(group);
    if (!options?.disableUndoRedo) {
      this.konvaHistory.save(this.layer);
    }
    this.layer.batchDraw();
    
    return group;
  }
  
  /**
   * Set or unset listeners/events from input box.
   * @param box 
   */
  defineBoxListeners(group: Konva.Group, rect: Konva.Rect, text: Konva.Text, options?: {disableUndoRedo: boolean}) {
    // TODO: Snapping lines

    // * SELECT BOX
    if (group.attrs.type === 'bbx') {
      rect.on('contextmenu', (event) => { this.konvaMenu.openMenu(event, this.layer, this.stage, this.transformer, group, this.menu) });
      rect.on('click', () => { 
        this.konvaEvent.selectBox(this.layer, this.stage, this.transformer, group);
        this.selectedBoxSubject.next(group); 
      });
    } else {
      group.on('contextmenu', (event) => { this.konvaMenu.openMenu(event, this.layer, this.stage, this.transformer, group, this.menu) });
      group.on('click', () => { 
        this.konvaEvent.selectBox(this.layer, this.stage, this.transformer, group) 
        this.selectedBoxSubject.next(group);
      });
    }

    // * HOVER/FOCUS STATE
    group.on('mouseover', () => this.konvaEvent.hoverBox(this.stage, group));
    group.on('mouseleave', () => this.konvaEvent.removeHoverStates(this.stage));

    // * DRAG
    group.on('dragmove', () => {
      this.konvaEvent.constrainBoxMovement(this.stage, this.image, group);
      this.selectedBoxSubject.next(group); 
    });
    if (!options?.disableUndoRedo) {
      group.on('dragstart', () => this.konvaHistory.save(this.layer));
      group.on('dragend', () => this.konvaHistory.save(this.layer));
    }

    // * TRANSFORM
    group.on('transform', (event: any) => {
      this.konvaEvent.updateTextSize(text, 18, this.stage.scaleX());
      this.konvaEvent.constrainBoxMovement(this.stage, this.image, group);
      this.selectedBoxSubject.next(group); 
    });

    // // * EDIT TEXT
    // text.on('dblclick', (event) => {
    //   // For left-click
    //   if (event.evt.button === LEFT_CLICK) {
    //     this.konvaRenameBox.openEditionFrame(this.layer, this.stage, this.transformer, group);
    //   }
    // });
  }

  getNodeCenter(node: Konva.Node): { x: number, y: number } {
    return { x: node.width() / 2, y: node.height() / 2 };
  }

  hideAllBoxes() {
    this.layer.find('.box').forEach(node => node.visible(false));
    this.layer.draw();
  }

  showAllBoxes() {
    this.layer.find('.box').forEach(node => node.visible(true));
    this.layer.draw();
  }

  undo() {
    if (this.konvaHistory.hasPreviousState()) {
      this.stage.removeChildren();
      this.layer = this.konvaHistory.getPreviousState();
      this.stage.add(this.layer);
      this.stage.batchDraw();
    }
  }

  redo() {
    if (this.konvaHistory.hasNextState()) {
      this.stage.removeChildren();
      this.layer = this.konvaHistory.getNextState();
      this.stage.add(this.layer);
      this.stage.batchDraw();
    }
  }

  async generateModel(modelData: ModelData) {
    // Globalize model
    this.model = modelData.model;
    // Image
    this.image = await this.createImage(modelData.image);
    this.layer.add(this.image);
    // Boxes
    this.model.rawBoxes?.forEach((rawBox: RawBox, index: number) => {
      const box: Konva.Group = this.createBox(rawBox.type, {fromData: {rawBox, index}, disableUndoRedo: true});
    });
  }

  async createImage(base64StringImage: string): Promise<Konva.Image> {
    return new Promise((resolve, reject) => {
      Konva.Image.fromURL(base64StringImage, (image) => {
        if (image) {
          image.x(0);
          image.y(0);
          resolve(image);
        } else {
          reject('Erreur lors du chargement de l\'image');
        }
      });
    });
  }

  center() {
    this.image.position({
      x: (this.stage.width() - this.image.width()) / 2,
      y: (this.stage.height() - this.image.height()) / 2,
    });
    this.layer.batchDraw();
  }

  move(direction: DirectionType) {
    let x = this.layer.x();
    let y = this.layer.y();
    switch (direction) {
      case 'top':
        y += this.moveBy;
        break
      case 'bottom':
        y -= this.moveBy;
        break
      case 'right':
        x -= this.moveBy;
        break
      case 'left':
        x += this.moveBy;
        break
    }
    const tween = new Konva.Tween({
      node: this.layer,
      easing: Konva.Easings.StrongEaseOut,
      duration: 0.75,
      x,
      y,
    });
    tween.play();
  }

  findSelectedBox(): Konva.Group | undefined {
    const groups = this.stage.children[0].find(`Group`);
    if (groups) {
      return groups.find(group => group.attrs.selected) as Konva.Group;
    }
    return;
  }

  updateSelection(boxData: BoxData | undefined) {
    if (!boxData) return
      const foundBox = this.findSelectedBox();
      if (foundBox) {
        console.warn(boxData, foundBox);
        foundBox.setAttrs({...foundBox.attrs,
          type: boxData.type,
          label: boxData.name,
          x: Number(boxData.x),
          y: Number(boxData.y),
          width: Number(boxData.width),
          height: Number(boxData.height)
        });
        const rect = foundBox.findOne('Rect') as Konva.Rect | undefined;
        if (rect) {
          rect.setAttrs({
            ...this.getRectConfigByType(boxData.type),
            width: foundBox.width(),
            height: foundBox.height()
          });
        }
        const text = foundBox.findOne('Text') as Konva.Text | undefined;
        if (text) {
          text.setText(boxData.name ?? '');
        }
        this.layer.draw();
        this.stage.draw();
      }
  }
}
