import { RectConfig } from "konva/lib/shapes/Rect";
import { ItemData } from "../database.model";
import { Category } from "./category.model";
import { Language } from "./language.model";
import { ModelImage } from "./image.model";
import { Config } from "../config.model";

export interface DocType extends ItemData {
  categoryId: number;
  category: Category;
}

export type BoxType = 'bbx' | 'txt' | 'img' | 'kpt' | 'obf' | 'regex';
export const BOX_CONFIGS: Partial<RectConfig> = {
  bbx: <RectConfig>{
    stroke: `black`,
    dash: [16, 8],
    strokeWidth: 4,
    fillEnabled: false,
    description: 'Bouding box'
  },
  txt: {
    fillEnabled: true,
    fill: `rgba(173, 216, 230, 0.8)`,
    stroke: `rgba(131, 164, 163)`,
    strokeWidth: 2,
    description: 'Text',
    dash: null,
  },
  img: {
    fillEnabled: true,
    fill: `rgba(255, 165, 0, 0.8)`,
    stroke: `rgba(189, 123, 0)`,
    strokeWidth: 2,
    description: 'Image',
    dash: null,
  },
  kpt: {
    fillEnabled: true,
    fill: `rgba(240, 128, 128, 0.8)`,
    stroke: `rgba(170, 90, 90)`,
    strokeWidth: 2,
    description: 'Keypoint',
    dash: null,
  },
  obf: {
    fillEnabled: true,
    fill: `rgba(144, 238, 144, 0.8)`,
    stroke: `rgba(100, 164, 100)`,
    strokeWidth: 2,
    description: 'Obfuscation',
    dash: null,
  },
  regex: {
    fill: `rgba(238, 130, 238, 0.8)`,
    stroke: `rgba(175, 100, 175)`,
    strokeWidth: 2,
    description: 'Regular expression',
    dash: null,
  },
  cbx: {
    fillEnabled: true,
    fill: `rgb(247, 168, 175, 0.4)`,
    stroke: `rgb(247, 168, 175)`,
    strokeWidth: 2,
    description: 'Checkbox',
    dash: null,
  },
  cmb: {
    fillEnabled: true,
    fill: `rgb(191, 141, 237, 0.6)`,
    stroke: `rgb(191, 141, 237)`,
    strokeWidth: 2,
    description: 'Comb',
    dash: null,
  }
};

export interface BoxData {
  name?: string,
  type: string,
  angle?: number,
  zIndex?: number,
  optional?: boolean,
  x?: number,
  y?: number,
  maxX?: number,
  maxY?: number,
  width?: number,
  height?: number
}

export const whitelistOptions = [
  { label: 'ASCII alphanumeric', value: 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789' },
  { label: 'ASCII alphanumeric extra', value: 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789\',.-()' },
  {
    label: 'French alphanumeric',
    value: 'aàâæbcçdeéèêëfghiîïjklmnoôœpqrstuùûüvwxyÿzAÀÂÆBCÇDEÉÈÊËFGHIÎÏJKLMNOÔŒPQRSTUÙÛÜVWXYŸZ0123456789'
  },
  {
    label: 'French alphanumeric extra',
    value: 'aàâæbcçdeéèêëfghiîïjklmnoôœpqrstuùûüvwxyÿzAÀÂÆBCÇDEÉÈÊËFGHIÎÏJKLMNOÔŒPQRSTUÙÛÜVWXYŸZ0123456789\',.-()'
  },
  { label: 'Numeric', value: '0123456789' },
  { label: 'Numeric date', value: '0123456789/-.' },
  { label: 'Uppercase ASCII alphanumeric', value: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789' },
  { label: 'Uppercase ASCII alphanumeric extra', value: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789\',.-()' },
  { label: 'Uppercase ASCII letters', value: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' },
  { label: 'Uppercase ASCII letters extra', value: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ\',.-()' },
]

export function getBoxStyles(key: string) {
  const config: RectConfig = BOX_CONFIGS[key];
  return {
    'background-color': config.fill,
    'border': `${config.strokeWidth + 'px'} ${config.dash ? 'dashed' : 'solid'} ${config.stroke}`,
  };
}

export function getBoxDescription(key: string) {
  const config: RectConfig = BOX_CONFIGS[key];
  return config['description'];
}

// WHEN CREATING A MODEL
export interface RawBox {
  height: number,
  width: number,
  x: number,
  y: number,
  zIndex: number,
  key: number,
  name: string,
  type: string,
  obfuscated: boolean, // False by default
  optional?: boolean, // Undefined by default / Nothing to do with this
  ocr: boolean // If Image/Obf/BBX = false by default; To be edited by user
}
  
export interface Model extends Partial<ItemData>, Partial<InputModelData> {
  rawBoxes: RawBox[],
  parentId: string,
  shiny: boolean, // False by default
  name: string,
  configs: Config[], // To return : Set configIds
  page: string,
  public: boolean, // False by default
  mobile?: boolean,
  type: string,
  popularity: number,

  docTypeId: number,

  languageId: number,

  imageIds: string[],

  version?: string, // Nothing for the moment
  validityDate?: Date,
  creationDate?: Date, // 
  featuresDate?: Date, // Compute date
  modificationDate?: Date //
}

export interface InputModelData {
  docType: DocType,  // !
  language: Language,  // !
  images: ModelImage[],  // !
}