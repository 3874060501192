import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BOX_CONFIGS, BoxData, getBoxDescription, getBoxStyles } from 'src/app/models/model/model.model';

@Component({
  selector: 'app-selection-panel',
  templateUrl: './selection-panel.component.html',
  styleUrl: './selection-panel.component.scss'
})
export class SelectionPanelComponent implements OnInit, OnChanges {
  @Input() box?: BoxData;
  @Output() boxChange = new EventEmitter<BoxData>();

  boxForm!: FormGroup;
  boxKeys = Object.keys(BOX_CONFIGS);

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.initForm();

    this.boxForm.valueChanges.subscribe((value: BoxData) => {
      this.boxChange.emit(value);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['box'] && this.box) {
      this.updateForm();
    }
  }

  initForm() {
    this.boxForm = this.fb.group({
      name: [''],
      type: [''],
      x: [],
      y: [],
      width: [],
      height: []
    });
  }

  updateForm() {
    if (this.box && this.boxForm) {
      this.boxForm.patchValue(this.box);
    }
    // x: [this.box.x, Validators.max(this.box.maxX!)],
    // y: [this.box.x, Validators.max(this.box.maxY!)]
  }

  getBoxStyles(key: string) {
    return getBoxStyles(key);
  }

  getBoxDescription(key: string) {
    return getBoxDescription(key);
  }
}
