import { ItemData } from "../database.model";
import { SchemaFile, SchemaDocument, SchemaPage, SchemaField } from '@doclab/schema';
import { LinkedModel } from "src/app/services/config/config-model.service";

export type SchemaLevel = 'file' | 'document' | 'page' | 'field';
export enum SchemaLevels {
  File = 'file',
  Document = 'document',
  Page = 'page',
  Field = 'field'
}
export function findSchemaLevel(input: string): SchemaLevel | undefined {
  for (const type of Object.values(SchemaLevels)) {
    if (input.toLowerCase().includes(type)) {
      return type;
    }
  }
  return undefined;
}

export interface RawSchema extends Partial<ItemData> {
	body: string;
}

export const TYPE_TRANSFORMER = {
	'txt': 'String',
	'img': 'Image',
	'cmb': 'String',
	'QRCode': 'String',
	'mrz': 'String',
	// TODO: Change Kpt and bbx 'cause don't exist
	// 'kpt': 'String',
	// 'bbx': 'String',	
}

export type SchemaKeys = Partial<{ fileKey: string, docKey: string, pageKey: string, fieldKey: string }>;

export interface SchemaSelection {
	level: SchemaLevel,
	keys: SchemaKeys,
	category?: string,

	// Content and parent content (if exists) for constraints
	parentContent?: SchemaNode,
	content: SchemaNode,

	// Related models with details
	linkedModels?: LinkedModel[]
}

// TODO: Add constraint in field in Schema lib to add SchemaField
export type SchemaNode = SchemaFile | SchemaDocument | SchemaPage | SchemaField | any;

export interface SchemaDataField {
	key: string,
	node: SchemaNode
}

export interface SchemaData {
	keys: SchemaKeys,
	node: SchemaNode
}